export default {
  name: "DetailVirtualAccount",

  data() {
    return {
      identity: {
        virtualAccountId: "",
      },
      dataForm: {
        virtualAccountNumber: "",
        accountNumber: "",
        statusId: "",
        nominal: "",
        cifIdName: "",
        transactionCode: "",
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
    };
  },
  methods: {
    routeToPageListVirtualAccount() {
      this.$router.push("list");
    },
    getIdentityFromSession() {
      const getTokenFromSession = sessionStorage.getItem("VIRTUAL_ACCOUNT_ID");
      this.identity.virtualAccountId = getTokenFromSession;
    },
    async getVirtualAccountById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListVirtualAccount();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "virtual-account/" + this.identity.virtualAccountId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.virtualAccountNumber = resp.data.data
              .virtualAccountNumber
              ? resp.data.data.virtualAccountNumber
              : "";
            this.dataForm.accountNumber = resp.data.data.accountNumber
              ? resp.data.data.accountNumber
              : "";
            this.dataForm.statusId = resp.data.data.statusId
              ? resp.data.data.statusId
              : "";
            this.dataForm.nominal = resp.data.data.nominal
              ? resp.data.data.nominal
              : "";
            this.dataForm.cifIdName = resp.data.data.cifIdName
              ? resp.data.data.cifIdName
              : "";
            this.dataForm.transactionCode = resp.data.data.transactionCode
              ? resp.data.data.transactionCode
              : "";
            this.dataForm.createdByUserCode = resp.data.data.createdByUserCode;
            this.dataForm.createdByUserName = resp.data.data.createdByUserName;
            this.dataForm.createdDate = resp.data.data.createdDate;
            this.dataForm.updatedDate = resp.data.data.updatedDate;
            this.dataForm.updatedByUserCode = resp.data.data.updatedByUserCode;
            this.dataForm.updatedByUserName = resp.data.data.updatedByUserName;
            this.dataForm.authorizedDate = resp.data.data.authorizedDate;
            this.dataForm.authorizedByUserCode =
              resp.data.data.authorizedByUserCode;
            this.dataForm.authorizedByUserName =
              resp.data.data.authorizedByUserName;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () => this.routeToPageListVirtualAccount(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListVirtualAccount(),
          });
        }
      }
    },
    helperClearIdentifierVirtualAccountDetailIdOnSessionStorage() {
      sessionStorage.removeItem("VIRTUAL_ACCOUNT_ID");
    },
    helperTokenAvailabilityChecker() {
      const token = sessionStorage.getItem("VIRTUAL_ACCOUNT_ID");
      if (!token) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListVirtualAccount();
      }
    },
  },
  beforeMount() {
    this.helperTokenAvailabilityChecker();
  },
  mounted() {
    this.getIdentityFromSession();
    this.getVirtualAccountById();
  },
  destroyed() {
    this.helperClearIdentifierVirtualAccountDetailIdOnSessionStorage();
  },
};
